.card-form-body {
    max-height: 450px;
    overflow-y: scroll;
    padding:5px;
}

.card-form-body::-webkit-scrollbar
{
    width: 5px;
}

.card-form-body::-webkit-scrollbar-track
{
    background: #fff;
}

.card-form-body::-webkit-scrollbar-thumb
{
    width: 5px;
    background: #e1e1e1;
    min-height: 10px;
}

.export-to-excel {
    right:0;
    width: 450px;
    height: 250px;
    box-shadow: 0 0 0 10px 0 rgba(0,0,0,0.5);
    border:1px solid #e1e1e1;
    overflow-y: auto;
    background: #fff;
}
