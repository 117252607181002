/* users */

.img-users {
    width:55px;
    height:55px;
    display: block;
    margin: 10px auto;
    border-radius: 50%;
}

.single-message p {
    padding: 0;
    margin: 0;
}

.single-message:hover {
    background-color: #eee;
    cursor: pointer;
}

.active-message {
    background-color: #e1e1e1;
}

.messages {
    background: #fff;
    max-height: calc(100vh - 330px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 15px;
}

.conversations{
    max-height: calc(100vh - 330px);
    overflow-y: auto;
    overflow-x: hidden;
}

.conversations::-webkit-scrollbar,
.messages::-webkit-scrollbar,
.attributes-div::-webkit-scrollbar
{
    width: 7px;
}

.conversations::-webkit-scrollbar-track,
.messages::-webkit-scrollbar-track,
.attributes-div::-webkit-scrollbar-track
{
    background: #fff;
}

.conversations::-webkit-scrollbar-thumb,
.messages::-webkit-scrollbar-thumb,
.attributes-div::-webkit-scrollbar-thumb
{
    width: 5px;
    background: #e1e1e1;
    min-height: 10px;
}

.message-user {
    float:left;
}

.message-guest {
    float:right;
    text-align: right;
}

.message-user .message-content {
    background-color: #ddd;
    display: inline-block;
    margin-bottom: 10px;
}

.message-guest .message-content {
    background-color: #0087fa;
    padding: 10px;
    border-radius: 5px;
    color: white;
    display: inline-block;
    margin-bottom: 10px;
    font-size:14px;
}

.message-guest .message-content p{
    padding: 0;
    margin:5px 0;
}

.message-user .message-content {
    background-color: #eaeaea;
    padding: 10px;
    border-radius: 5px;
    color: #0d0d0d;
    display: inline-block;
    margin-bottom: 10px;
    font-size:14px;
}

.message-user .message-content p{
    padding: 0;
    margin:5px 0;
}

.livechat-title {
    margin-bottom: 10px;
    background-color: #eee;
    padding: 10px;
    border-radius: 10px;
    color: #0d0d0d;
}

.attributes-div {
    max-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}

.attributes-div label{
    font-size: 12px;
}
