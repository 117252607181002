.training-text::-webkit-scrollbar
{
    width: 7px;
}

.training-text::-webkit-scrollbar-track
{
    background: #fff;
}

.training-text::-webkit-scrollbar-thumb
{
    width: 5px;
    background: #e1e1e1;
    min-height: 10px;
}
