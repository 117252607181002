.item-container {
    padding: 20px;
    border-radius: 5px;
    border: 1px dashed #e1e1e1;
    margin-bottom: 5px;
}

.item-container:hover{
    background-color: #eee;
    transition: all 1s ease;
}

.react-time-picker__wrapper{
    border: thin solid #d2d2d2 !important;
    width: 100% !important;
    border-radius: 5px;
    padding: 5px;
}
