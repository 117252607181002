body {
    background: linear-gradient(to bottom,#fff 50%, #f0f0f0 100%);
    min-height: 100vh;
}

.resume-card {
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.5);
    border-radius: 10px;
    color: white;
}

.resume-card:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.7);
    transition: transform 1s ease;
    transform: scale(1.05);
}

.card-1 {
    background: url('./assets/cards_bg_1.jpg') center center scroll no-repeat;
    background-size: cover;
}

.card-2 {
    background: url('./assets/cards_bg_2.jpg') center center scroll no-repeat;
    background-size: cover;
}

.card-3 {
    background: url('./assets/cards_bg_3.jpg') center center scroll no-repeat;
    background-size: cover;
}

.custom-button{
    background-color: #fff;
    border-color: #74a8d2;
    border-radius: 50px;
    color: #74a8d2;
    font-weight: 600;
    font-size: 18px;
}

.custom-button:hover{
    background-color: #f4f4f4;
    transition: all 1s ease;
    border-color: #74a8d2;
    border-radius: 50px;
    color: #74a8d2;
}

.card-button{
    height: 140px;
    border: 1px solid;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 15px;
}

.card-button p {
    margin: 0;
    padding: 0;
}

.zoom:hover{
    cursor: pointer;
    transition: transform 1s ease;
    transform: scale(1.05);
}

.custom-display-flex {
    display: flex;
    justify-content: space-around;
    align-items: end;
}

.custom-modal-body {
    width: 120%;
}

.create_group_button {
    padding-inline: 32px;
}